//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserContext from '../../contexts/UserContext';
import Api from '../../services/Api'
import ListItemCashRegister from '../../components/ListItemCashRegister'
import Functions from '../../services/Functions';
import './styles.css';
import '../../App.css';

export default function DashboardCashRegister({setLoading}) {
  const {user} = useContext(UserContext);
  const isAdmin = user[0].user.admin;
  const isAdminShop = user[0].user.adminShop;
  const [cashRegisters, setCashRegisters] = useState([]);
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(user[0].user.adminShop ? user[0].user.shop._id : "");
  const [dateRange, setDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]);

  /*useEffect(() => {
    const today = new Date();
    const [firstDay, lastDay] = Functions.getFirstAndLastDayOfMonth(today);
    setDateRange([firstDay, lastDay]);
  }, []); // O array vazio [] garante que o efeito só seja executado na montagem*/

  const [startDate, endDate] = dateRange;

 // Executa assim q a página é aberta;
  useEffect(() => {
    if(isAdmin){
      loadAllShops();
    }
  }, []);

  useEffect(() => {
    if(endDate && selectedShop){
      loadCashRegister(selectedShop);
    }
  }, [endDate, selectedShop]);

  async function loadCashRegister(shop) {
    try {
      setLoading(true);
      const response = await Api.getItems(`/get-cash-register/${shop}/${startDate.toISOString().substr(0,10)}/${endDate.toISOString().substr(0,10)}`);
      setCashRegisters(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadAllShops() {
    try {
      setLoading(true);
      const response = await Api.getShops();
      setShops(response);
      setSelectedShop(response[0]?._id);
      //loadCashRegister(user[0].user.adminShop ? user[0].user.shop._id : response[0]?._id);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addCashRegister(){
    const copy = Array.from(cashRegisters);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    copy.unshift({
      _id: copy.length +1,
      date: "", 
      shop: user[0]?.user?.shop, 
      user: user[0]?.user,
      quantityOrders: 0, 
      operations: [
        {
          operation: "inflow", 
          name: "Vendas Aplicativo", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas Ifood", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas Aiqfome", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas Delivery Much", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas extras", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Cartão Balcão", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Cartão Delivery", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Taxa Delivery", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Dinheiro", 
          value: 0
        },
        { 
          operation: "outflow", 
          name: "PIX", 
          value: 0
        },
      ],
      active: true
    });
    await setCashRegisters(copy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
  }

  return (
    <div id="leadList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4 col-md-4">
          {isAdmin ? 
            <select className="title" value={selectedShop} style={{border: 0, background: 'transparent', cursor: 'pointer'}} onChange={event => setSelectedShop(event.target.value)}>
              {shops.map((item) => (
                <option key={item._id} value={item._id}>{item.name}</option>
              ))}
            </select>
          : 
            <span className="title">Fechamentos</span>
          }
        </div>
        <div className="col-4 col-md-4 text-center">
          <DatePicker 
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            className="titleDate"
            dateFormat={'dd/MM/yyyy'}
            withPortal 
          />
        </div>
        <div className="col-4 col-md-4 text-right">
          {isAdminShop &&
            <button type="button" onClick={addCashRegister} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
          }
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {cashRegisters.length > 0 ?
            cashRegisters.map(item => (
              <ListItemCashRegister
                key={item._id}
                id={item._id}
                date={item.date}
                shop={item.shop}
                user={item.user}
                quantityOrders={item.quantityOrders}
                operations={item.operations}
                //updateAt={item.updateAt}
                createdAt={item.createdAt}
                loadCashRegister={(shop) => loadCashRegister(shop)}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum registro encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}